<script setup lang="ts">
import LoginTemplate from '@/components/templates/LoginTemplate/LoginTemplate.vue';

const route = useRoute();
const router = useRouter();
const redirectLocation = computed(
  () => (route.query.redirect as string | null) ?? '/my/overview'
);
const onLogin = () => router.push(redirectLocation.value);

onMounted(async () => {
  const { isAuthenticated } = await useAuthenticationState();
  if (isAuthenticated.value) {
    router.replace({ path: redirectLocation.value });
  }
});
</script>

<template>
  <ClientOnly><LoginTemplate @on-login="onLogin" /></ClientOnly>
</template>
