import type { Client } from '@/composables/microservice/useRestClient';
import { useRestClient } from '@/composables/microservice/useRestClient';

type LoginResponse =
  | {
      ok: true;
      token: string;
    }
  | {
      ok: false;
    };

export const { useMailLogin, useAsyncMailLogin } = useRestClient(
  'MailLogin',
  'hotel',
  async (cli: Client<LoginResponse>, email: string, password: string) =>
    await cli<LoginResponse>('user/login/', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    })
);
