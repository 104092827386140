<script setup lang="ts">
import LineLoginButton from './LineLoginButton.vue';
import ButtonFlat from 'theme/components/atoms/ButtonFlat.vue';
import InputText from 'theme/components/atoms/InputText.vue';
import InputLabel from 'theme/components/molecules/InputLabel.vue';
import InputPassword from 'theme/components/molecules/InputPassword.vue';
import SimplePageContainer from 'theme/components/molecules/SimplePageContainer.vue';

const emit = defineEmits<{ (e: 'onLogin'): void }>();
const email = ref('');
const password = ref('');
const isFormValid = computed(
  () => email.value.length > 0 && password.value.length > 0
);
const { previousLoginMethod } = usePreviousLoginMethod();
const { login: loginUsingEmail, loading: loadingLoginUsingEmail } =
  useLoginUsingEmail();
const onSubmit = async () => {
  await loginUsingEmail(email.value, password.value);
  onLoginFinished();
};
const onLoginFinished = () => emit('onLogin');
</script>

<template>
  <SimplePageContainer>
    <main :class="$style.page">
      <h3 :class="[$style.page__subheading, $style.subheading]">ログイン</h3>

      <div
        v-if="previousLoginMethod === 'line'"
        :class="[$style.page__lineLogin, $style.lineLogin]"
      >
        <p :class="$style.lineLogin__notice">
          ▼ 前回は LINE でログインしました
        </p>
        <LineLoginButton :class="$style.lineLogin__button" />

        <hr :class="$style.lineLogin__rule" />
      </div>

      <form
        :class="[$style.page__form, $style.form]"
        @submit.prevent="onSubmit"
      >
        <p v-if="previousLoginMethod === 'email'" :class="$style.form__notice">
          ▼ 前回はメールアドレスでログインしました
        </p>
        <div :class="$style.form__item">
          <label>
            <InputLabel label="メールアドレス">
              <InputText
                v-model="email"
                :class="$style.formItem__input"
                type="email"
                name="email"
                placeholder="awesome@okane-kenko.jp"
              />
            </InputLabel>
          </label>
        </div>

        <div :class="[$style.form__item, $style.formItem]">
          <label>
            <InputLabel label="パスワード">
              <InputPassword
                v-model="password"
                :class="$style.formItem__input"
                placeholder="設定したパスワード"
                name="password"
                :invalid="false"
              />
            </InputLabel>
          </label>
        </div>

        <ButtonFlat
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingLoginUsingEmail"
          variant="primary"
          :class="$style.form__button"
          >ログインする</ButtonFlat
        >
      </form>

      <div :class="[$style.page__passwordReset, $style.passwordReset]">
        <NuxtLink :to="{ path: '/reset_password/start' }"
          >パスワードを再設定する方はこちら</NuxtLink
        >
      </div>

      <div
        v-if="previousLoginMethod !== 'line'"
        :class="[$style.page__lineLogin, $style.lineLogin]"
      >
        <hr :class="$style.lineLogin__rule" />

        <LineLoginButton :class="$style.lineLogin__button" />
      </div>
    </main>
  </SimplePageContainer>
</template>

<style module lang="scss">
.page {
  max-width: 400px;
  margin: 0 auto;

  &__form {
    margin-top: 16px;
  }

  &__passwordReset {
    width: 100%;
    margin-top: 24px;
  }

  &__lineLogin {
    width: 100%;
  }
}

.passwordReset {
  display: flex;
  justify-content: center;
}

.lineLogin {
  text-align: center;
  &__notice {
    color: $color-text-and-object-accent-orange;
    font-weight: bold;
    margin-bottom: 16px;
    margin-top: 24px;
  }

  &__button {
    margin: 0 auto;
  }

  &__rule {
    height: 0;
    border: 0;
    border-top: 1px solid $color-border-low-emphasis;
    margin: 24px 0;
  }
}

.subheading {
  width: 100%;
  font-size: 24px;
  text-align: center;
}

.form {
  &__item {
    width: 100%;
    &:nth-child(n + 2) {
      margin-top: 24px;
    }
  }

  &__button {
    margin-top: 32px;
    width: 100%;
  }

  &__notice {
    color: $color-text-and-object-accent-orange;
    font-weight: bold;
    margin-top: 24px;
  }
}

.formItem {
  &__input {
    width: 100%;
  }
}
</style>
