<script setup lang="ts">
import { useGetAuthUrl } from '@/composables/microservice/lexington/useGetAuthUrl';
import ButtonLineLogin from 'theme/components/molecules/ButtonLineLogin.vue';
import PromptUserToLoginWithDefaultBrowser from 'theme/components/templates/LoginTemplate/PromptUserToLoginWithDefaultBrowser.vue';

const route = useRoute();
const redirectDestination = computed(
  () => (route.query.redirect as string | null | undefined) ?? '/my'
);
const { shortSessionId, longSessionId } = useSession();
const { channelId } = useChannelId();
const params = computed(() => ({
  redirect: redirectDestination.value,
  channelId: channelId.value,
  shortSessionId: shortSessionId.value,
  longSessionId: longSessionId.value,
}));
const lineAuthUrl = ref('');
const { fetch } = useGetAuthUrl();
const {
  PromptUserToLoginWithDefaultBrowserModal,
  openPromptUserToLoginWithDefaultBrowserModal,
} = useModalNamed('promptUserToLoginWithDefaultBrowser');

onBeforeMount(async () => {
  try {
    lineAuthUrl.value = await fetch(params.value);
  } catch (_) {
    // TODO: エラーハンドリングする
  }
});
</script>

<template>
  <ButtonLineLogin
    :auth-url="lineAuthUrl"
    v-bind="$attrs"
    @try-to-login-with-instagram-browser="
      openPromptUserToLoginWithDefaultBrowserModal
    "
  />
  <PromptUserToLoginWithDefaultBrowserModal closeable>
    <PromptUserToLoginWithDefaultBrowser />
  </PromptUserToLoginWithDefaultBrowserModal>
</template>
