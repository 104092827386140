import { useMailLogin } from './microservice/hotel/useMailLogin';
import { HotelToken } from './useCredentialManager/hotel';

export const useLoginUsingEmail = () => {
  const { fetch } = useMailLogin();
  const { tokenManager } = useHotelTokenManager();
  const alert = useAlert();
  const { setPreviousLoginMethod } = usePreviousLoginMethod();

  const { loading, load } = useLoading(
    async (...params: Parameters<typeof fetch>) => {
      try {
        const result = await fetch(...params);
        if (!result.ok) {
          alert.error('メールアドレスまたはパスワードが間違っています。');
          return;
        }
        await tokenManager.updateCredential(new HotelToken(result.token));
        setPreviousLoginMethod('email');
      } catch (e) {
        captureException(e);
        alert.error(
          '通信に失敗しました。お手数ですが、時間をおいて再度お試しください。'
        );
      }
    }
  );

  return {
    loading,
    login: async (...params: Parameters<typeof fetch>) => {
      if (loading.value) {
        alert.error('ログイン処理中です。');
        return;
      }
      await load(...params);
    },
  };
};
